<template>
  <div class="applyInvoice-container">
    <div class="apply-box">
      <div class="apply-box-top">
        <span>业务类型</span>
        <el-checkbox v-model="goodsType" label="1" @change="selectType">商标服务</el-checkbox>
        <el-checkbox v-model="goodsType" label="2" @change="selectType">专利服务</el-checkbox>
        <el-checkbox v-model="goodsType" label="3" @change="selectType">版权服务</el-checkbox>
       
      </div>
      <!-- 商标发票 -->
      <div style="clear:both"></div>
      <div class="apply-box-list">
        <div class="apply-box-list-top">
          <el-checkbox class="flml" @change="selectProduct(isSelectAll)" v-model="isSelectAll" v-bind:checked="isSelectAll"></el-checkbox>
          <div>订单编号</div>
          <div>商品名称</div>
          <div>服务类型</div>
          <div>订单金额</div>
          <div>支付方式</div>
          <div>下单时间</div>
        </div>
        <div>
          <div class="apply-box-list-detail" v-for="(v,k) in arr" :key="k">
            <el-checkbox class="flml" v-model="v.isSelect" v-bind:checked="v.isSelect"></el-checkbox>
            <div :title="v.orderCode">{{v.orderCode}}</div>
            <div>{{v.serviceTypeName}}</div>
            <div>{{v.goodsName}}</div>
            <div>￥{{v.orderPrice}}</div>
            <div>{{payType[v.payType]}}</div>
            <div>{{v.createdTime}}</div>
          </div>
        </div>
      </div>
      <div class="apply-box-buttom" @click="invoiceApply"></div>
      <div style="clear:both"></div>
    </div>
    <!-- 商标申请发票 -->
    <div class="apply-box-tan" v-show="tan">
      <div class="apply-box-tan-div">
        <span>申请发票</span>
        <img @click="cha" :src="require('/static/images/cha.png')" alt="">
        <div class="apply-box-tan-type">
          <span class="apply-box-tan-type-span1">选择开票类型：</span>
          <input v-model="kai" value="one" name="piao"  type="radio"><span class="apply-box-tan-type-span2">普通发票（电子发票）</span>
          <input v-model="kai" value="two" name="piao" type="radio"><span>专用发票（纸质发票）</span>
        </div>
        <div class="apply-box-tan-add">
          <span>已有开票信息：</span>
          <select v-model="pick">
            <option value="0">请选择</option>
            <option v-for="(v,k) in arr2" v-bind:value="v.invoiceCode" :key="k">{{v.invoiceName}}</option>
          </select>
          <div @click="addInvoice">新增</div>
        </div>
        <div @click="comfire" class="apply-box-tan-comfire">确定</div>
      </div>
    </div>
    <div style="clear:both;"></div>
  </div>
</template>

<script>
export default {
  name: 'applyInvoice',
  data(){
      return{
          kai:'one',
          tan:false,
          pick:'0',
          arr:[],
          arr2:[],
          payType:{
          '1': '微信',
          '2': '支付宝',
          '3': '银行转账'
        },
       id:'',
       goodsType:[]
      }
  },
  components: {

  },
  watch:{
      "kai"(newValue){
          if(newValue =='one'){
            this.pick = "0"
              this.getlist();
          }else if(newValue=='two'){
            this.pick = "0"
            this.getlist();
          }
      },
  },
  computed:{
    //    全选
      isSelectAll:{
            get:function(){
              if(this.arr !=0){
                return this.arr.every(function (val) { return val.isSelect});
            }else{
                return false;
            }
        },
        set:function(){
              if(this.arr !=0){
                return this.arr.every(function (val) { return val.isSelect});
            }else{
                return false;
            }
        }
      }
  },
  methods:{
    tip(type,msg){
          return this.$message({
              type:type,
              message:msg,
              showClose:true,
              duration:1500
          })
    },
    selectType(){
      this.arr = [];
      this.getdata()
    },
    selectProduct:function(_isSelect){
                for (var i = 0; i< this.arr.length; i++) {
                    this.arr[i].isSelect = !_isSelect;
                }
            },
    invoiceApply(){
        var prolist = this.arr.filter(function (val) { return val.isSelect});
        if(prolist.length == 0){
          this.$alert('请选择开票的订单','温馨提醒',{
            confirmButtonText: '确定'
        })
            return false
        }
       this.tan = true;
    },
    cha(){
       this.tan = false;
    },
    comfire(){
        var prolist = this.arr.filter(function (val) { return val.isSelect});
        // console.log(prolist)
        var list = [];
        for (var i = 0;i< prolist.length; i++) {
         list.push(prolist[i].orderCode)
        }
        // console.log(list)
  
       if(this.pick == "0"){
          return false;
       }else{
         for(var i = 0;i<this.arr2.length;i++){
           if(this.pick == this.arr2[i].invoiceCode){
             this.id = this.arr2[i].id;
           }
         }
          this.$router.push({
            path:'/personal/applyComfire',
            query:{
              id:this.id,
              orderCodes:list.join(','),
              code:this.pick
            }
          })
       }
    },
    addInvoice(){
       this.$router.push('/personal/manageForm')
       this.tan = false;
    },
    getdata(){
        this.$http.post(this.GLOBAL.new_url+'/invoice/webapi/orderlist',{
          goodsType:this.goodsType.join(",")
                },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                  // console.log(res)
                  if(res.data.code == "200"){
                    this.arr = res.data.data.list;
                    var _this=this;
                    this.arr.map(function (item) {
                        _this.$set(item, 'isSelect', false);
                    })
                  }else{
                    this.arr = [];
                  }
                })

    },
    getlist(){
      
          if(this.kai == 'one'){
          this.$http.post(this.GLOBAL.new_url+'/invoice/webapi/list',{
                      pageSize:1000,
                       invoiceType:"1",
                      pageNum:1
                  },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                                  // console.log(res)
                    if(res.data.code == '200'){
                      this.arr2 = res.data.data.list;
                    }
                  })
       }else{
         this.$http.post(this.GLOBAL.new_url+'/invoice/webapi/list',{
                    pageSize:1000,
                    invoiceType:"2",
                    pageNum:1
                },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                  if(res.data.code == '200'){
                      this.arr2 = res.data.data.list;
                    }
                })
       }
       
    }
  },
  mounted(){
    this.getdata();
    this.getlist();
    
  }
}
</script>

<style scoped>
  .apply-box{
    width: 1015px;
    margin-left: 16px;
    float: left;
    /* margin-bottom: 100px; */
    margin-top: 12px;
    background-color: #fff;
    padding-top: 28px;
    box-sizing: border-box;
    min-height: 1024px;
  }
  .apply-box-top{
    width: 887px;
    margin: 0px 0px 12px 64px;
    border-bottom: 1px solid #eee;
    height: 28px;
    font-family: "PingFangSC";
    font-size: 14px;
    cursor: pointer;
    color: #666;
    position: relative;
  }
  .apply-box-top>span{
    font-size: 14px;
    margin-right: 15px;
  }
  .apply-box-list-top{
    height: 36px;
    width: 885px;
    line-height: 36px;
    border: 1px solid #eee;
    margin: 0 auto;
    background-color: #fafafa;
  }
  .apply-box-list-top>div{
    float: left;
    width: 110px;
    text-align: center;
    font-family: "PingFangSC";
    font-size: 14px;
    font-weight: 600;
    color: #333333;
  }
  .apply-box-list-top>div:nth-of-type(1){
    width:177px;
  }
  .apply-box-list-top>div:nth-of-type(6){
    width:170px;
  }
  .apply-box-list-detail{
    height: 22px;
    /* margin: 14px 0 27px; */
    width: 885px;
    margin: 0 auto;
    height: 36px;
    line-height: 36px;
    border: 1px solid #eee;
    border-top: none;
    box-sizing: border-box;
  }
  .apply-box-list-detail>input{
    float: left;
    width: 16px;
    height: 16px;
    margin-top: 10px;
    margin-left: 20px;
    cursor: pointer;
  }
 .apply-box-list-detail>div{
    float: left;
    width: 110px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-family: "PingFangSC-Light";
    font-size: 14px;
    font-weight: 100;
    color: #333333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .apply-box-list-detail>div:nth-of-type(1){
    width:180px;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
  }
  .apply-box-list-detail>div:nth-of-type(6){
    width:170px;
  }
  .apply-box-page{
    width: 400px;
    text-align: center;
    margin: 0 auto 37px;
    margin-top: 37px;
  }
  .apply-box-buttom{
    width: 108px;
    height: 36px;
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    background: url(../../../../static/images/invalid-name1j.png) no-repeat;
    /* border-image-source: linear-gradient(to top, #ba6100, #dc773b); */
    border-image-slice: 1;
    /* background-image: linear-gradient(to bottom, #f79000, #EB5E00), linear-gradient(to top, #ba6100, #dc773b); */
    background-origin: border-box;
    background-clip: content-box, border-box;
    text-align: center;
    line-height: 60px;
    margin: 40px auto 50px;
    font-family: "PingFangSC";
    font-size: 28px;
    color: #ffffff;
    cursor: pointer;
  }
  .apply-box-tan{
    background: rgba(0,0,0, 0.8);
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .apply-box-tan-div{
    width: 687px;
    height: 361px;
    background-color: #ffffff;
    position: absolute;
    top:50%;
    left: 50%;
    z-index: 30;
    transform: translate(-50%,-50%);
  }
  .apply-box-tan-div>span{
    font-family: "PingFangSC";
    font-size: 14px;
    color: #333333;
    position: absolute;
    top: 30px;
    left: 316px;
  }
  .apply-box-tan-div>img{
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    height: 16px;
    width: 16px;
  }
  .apply-box-tan-type{
    position: absolute;
    height: 28px;
    top: 103px;
    left: 62px;
  }
  .apply-box-tan-type>span,.apply-box-tan-add>span{
    font-family: "PingFangSC";
    font-size: 14px;
    font-weight: 300;
    vertical-align: middle;
    color: #333333;
  }
  .apply-box-tan-type-span1{
    margin-right: 92px;
  }
  .apply-box-tan-type>input{
    width: 16px;
    height: 16px;
    margin-right: 12px;
    vertical-align: middle;
    cursor: pointer;
  }
  .apply-box-tan-type-span2{
    margin-right: 25px;
  }
  .apply-box-tan-add{
     position: absolute;
     width: 563px;
     height: 40px;
     line-height: 40px;
     top: 153px;
     left: 62px;
  }
  .apply-box-tan-add>span{
    margin-right: 95px;
  }
  .apply-box-tan-add>div{
    float: right;
    width: 80px;
    height: 36px;
    background: url(../../../../static/images/invalid-name1k.png);
    text-align: center;
    line-height: 36px;
    font-family: "PingFangSC";
    font-size: 14px;
    color: #EB5E00;
    cursor: pointer;
    margin-top: 3px;
  }
  .apply-box-tan-add>select{
    width: 229px;
    height: 36px;
    border-radius: 4px;
    padding-left:15px;
    outline: none;
    border: solid 1px #d6d6d6;
    box-sizing: border-box;
  }
  .apply-box-tan-comfire{
    position: absolute;
    width: 563px;
    height:36px;
    line-height: 36px;
    bottom: 64px;
    left: 300px;
    width: 80px;
    height: 36px;
    background: url(../../../../static/images/invalid-name1l.png);
    border-width: 2px;
    border-image-slice: 1;
    font-family: "PingFangSC";
    font-size: 14px;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }
  .flml {
    margin-right: 4px !important;
    float: left !important;
    vertical-align: middle;
    margin-left: 20px !important;
  }
</style>
<style >
  .el-checkbox__input.is-checked+.el-checkbox__label {
   color: #EB5E00;
 }
 .el-checkbox__input.is-checked .el-checkbox__inner {
   background-color: #EB5E00;
   border-color: #EB5E00;
 }
</style>


